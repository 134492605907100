import React from "react";
import "./index.css"

function AutorCats() {
    return (
        <div className="autor">
            <p>Website hecha por <a href="https://www.linkedin.com/in/mariano-vitale-tasso-2984311b8/" target="_blank" className="a-autor">Marvit</a></p>
        </div>
    )
}

export { AutorCats }